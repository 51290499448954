import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    public http: HttpClient
  ) { }

  getActiviesFormatSimple(id) {
    return this.http.get(`${environment.api}activies/format/${id}`)
      .toPromise()
      .then(data => data)
  }


  postTask(data) {
    return this.http.post(`${environment.api}tasks`,data)
      .toPromise()
      .then(data => data)
  }
  

  editTask(data) {
    return this.http.post(`${environment.api}tasks/${data.tasks_id}`,data)
      .toPromise()
      .then(data => data)
  }

  deleteTask(id) {
    return this.http.delete(`${environment.api}tasks/${id}`)
      .toPromise()
      .then(data => data)
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ProjectIncidentsService {

  constructor(
    public http: HttpClient
  ) { }

  getProject() {
    return this.http.get(`${environment.api}project-incidents`)
      .toPromise()
      .then(data => data)
  }

  postProjectIncidents(data) {
    return this.http.post(`${environment.api}project-incidents`, data)
      .toPromise()
      .then(data => data)
  }

  updateProjectIncidents(data) {
    return this.http.put(`${environment.api}project-incidents/${data.id}`, data)
      .toPromise()
      .then(data => data)
  }

  deleteProjectIncidents(id) {
    return this.http.delete(`${environment.api}project-incidents/${id}`)
      .toPromise()
      .then(data => data)
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WeatherAPIService {
  weather: any = "--"
  constructor(
    public http: HttpClient
  ) { }

  weatherC(lat, lng) {
    return this.http.get(`https://weather.contrateumdev.com.br/api/weather?lat=${lat}&lon=${lng}`)
      .toPromise()
      .then((data: any) => {
        this.weather = parseInt(data.main.temp)
      })
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FinishService {

  constructor(
    private http:HttpClient
  ) { }

  getfinish(id) {
    return this.http.get(`${environment.api}finish/get/${id}`)
      .toPromise()
      .then(data => data)
  }


  postfinish(data) {
    return this.http.post(`${environment.api}finish`,data)
      .toPromise()
      .then(data => data)
  }

  
  postfinishM(data) {
    return this.http.post(`${environment.api}finishm`,data)
      .toPromise()
      .then(data => data)
  }

  
  putfinish(data) {
    return this.http.put(`${environment.api}finish/${data.id}`,data)
      .toPromise()
      .then(data => data)
  }
}
import { WeatherAPIService } from './weather-api.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor(
    public platform: Platform,
    public weatherAPIService: WeatherAPIService
  ) { }

  async geoLocation() {
    if (!environment.debug) {
      console.log("Geo Nativa")
      const coordinates = await Geolocation.getCurrentPosition();
      this.weatherAPIService.weatherC(coordinates.coords.latitude, coordinates.coords.longitude)
      console.log('Current position:', coordinates);
    } else {
      navigator.geolocation.getCurrentPosition(data => {
        this.weatherAPIService.weatherC(data.coords.latitude, data.coords.longitude)
      })
    }

  }
}

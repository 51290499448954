import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ClosureService {
  constructor(
    private http: HttpClient
  ) { }

  getClosure(id) {
    return this.http.get(`${environment.api}closure/get/${id}`)
      .toPromise()
      .then(data => data)
  }


  postClosure(data) {
    return this.http.post(`${environment.api}closure`, data)
      .toPromise()
      .then(data => data)
  }

  deleteClosure(id) {
    return this.http.delete(`${environment.api}closure/${id}`)
      .toPromise()
      .then(data => data)
  }


  putClosure(data) {
    return this.http.put(`${environment.api}closure/${data.id}`, data)
      .toPromise()
      .then(data => data)
  }


  getClosureAnomalies(id) {
    return this.http.get(`${environment.api}closure-anomalies/get/${id}`)
      .toPromise()
      .then(data => data)
  }


  postClosureAnomalies(data) {
    return this.http.post(`${environment.api}closure-anomalies`, data)
      .toPromise()
      .then(data => data)
  }


  deleteClosureAnomalies(id) {
    return this.http.delete(`${environment.api}closure-anomalies/${id}`)
      .toPromise()
      .then(data => data)
  }


  putClosureAnomalies(data) {
    return this.http.put(`${environment.api}closure-anomalies/${data.id}`, data)
      .toPromise()
      .then(data => data)
  }
}
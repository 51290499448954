import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ActiviesService {
  public value_project: any = 0
  constructor(
    public http: HttpClient
  ) { }

  getActivies(data) {

    return this.http.post(`${environment.api}activies/get`, data)
      .toPromise()
      .then(data => data)
  }

  postActivies(data, file) {
    var formData = new FormData;
    formData.append("file", file)
    formData.append("name", data.name)
    formData.append("project_id", data.project_id)
    formData.append("description", data.description)
    formData.append("unit", data.unit)
    formData.append("quantity", data.quantity)
    formData.append("status", data.status)
    formData.append("order", data.order)
    formData.append("hours", data.hours)
    formData.append("created_at", data.created_at)
    return this.http.post(`${environment.api}activies`, formData)
      .toPromise()
      .then(data => data)
  }

  updateActivies(data, file) {
    var formData = new FormData;
    formData.append("file", file)
    formData.append("name", data.name)
    formData.append("project_id", data.project_id)
    formData.append("description", data.description)
    formData.append("unit", data.unit)
    formData.append("quantity", data.quantity)
    formData.append("status", data.status)
    formData.append("hours", data.hours)
    formData.append("order", data.order)
    return this.http.post(`${environment.api}activies/${data.id}`, formData)
      .toPromise()
      .then(data => data)
  }

  deleteActivies(id) {
    return this.http.delete(`${environment.api}activies/${id}`)
      .toPromise()
      .then(data => data)
  }
}

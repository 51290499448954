import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UploadArchivesService {

  constructor(
    public http: HttpClient
  ) { }

  uploadArchives(data) {
    var formData = new FormData;
    for (let i = 0; i < data.length; i++) {
    formData.append("file[]", data[i].file)
    formData.append("name[]", data[i].name)
    formData.append("type[]", data[i].type)
    formData.append("id_type[]", data[i].id_type)
    formData.append("format_archive[]", data[i].format_archive)
    formData.append("project_id[]", data[i].project_id)
  }
  console.log(data)
    return this.http.post(`${environment.api}archives`, formData)
      .toPromise()
      .then(data => data)
  }
}

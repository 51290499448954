import { UtilsService } from './../../service/utils.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GuardGuard implements CanActivate {
  check = false
  constructor(
    private utils: UtilsService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.utils.checkTokenService.tokenCheck()) return true;
    /* clear storage */
    this.utils.storageService.clear()
    /* router */
    this.utils.router.navigate(["/login"])
    return false;

  }

}

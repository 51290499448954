import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserProjectService {

  dateInfo: any = []
  day: any = ""
  dayHeader: string = ""
  dayFormatter: string = ""
  weekType: String = "DIA"

  constructor(
    public http: HttpClient
  ) { }

  getUserProject(id, data) {
    return this.http.post(`${environment.api}user-project/${id}`, data)
      .toPromise()
      .then(data => data)
  }

  postUserProject(data) {
    return this.http.post(`${environment.api}user-project`, data)
      .toPromise()
      .then(data => data)
  }

 deleteUserProject(id) {
    return this.http.delete(`${environment.api}user-project/${id}`)
      .toPromise()
      .then(data => data)
  }

  postUserProjectSearch(data) {
    return this.http.post(`${environment.api}userproject/search`, data)
      .toPromise()
      .then(data => data)
  }


  getAllUsersProject() {
    return this.http.post(`${environment.api}userproject/all`, {})
      .toPromise()
      .then(data => data)
  }


  updateUserProject(data) {
    return this.http.put(`${environment.api}user-project/${data.id}`, data)
      .toPromise()
      .then(data => data)
  }

  postOrUpdatePresentUser(data) {

    return this.http.post(`${environment.api}user-project/add/present`, data)
      .toPromise()
      .then(data => data)
  }
}

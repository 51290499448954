import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(
    private http: HttpClient
  ) { }

  getGallery(id) {
    return this.http.get(`${environment.api}archives/get/${id}`)
      .toPromise()
      .then(data => data)
  }

  delete(id) {
    return this.http.delete(`${environment.api}archives/${id}`)
      .toPromise()
      .then(data => data)
  }


}

import { ClosureService } from './closure.service';
import { FinishService } from './finish.service';
import { InspectionService } from './inspection.service';
import { ImplantationService } from './implantation.service';
import { UtilitiesService } from './utilities.service';
import { ProjectIncidentsService } from './project-incidents.service';
import { OtherIncidentsService } from './other-incidents.service';
import { EquipmentsService } from './equipments.service';
import { AnamoliesService } from './anamolies.service';
import { UserProjectService } from './user-project.service';
import { GalleryService } from './gallery.service';
import { UploadArchivesService } from './upload-archives.service';
import { TaskService } from './task.service';
import { ActiviesService } from './activies.service';
import { ProjectService } from './project.service';
import { GeolocationService } from './geolocation.service';
import { WeatherAPIService } from './weather-api.service';
import { Platform, AlertController } from '@ionic/angular';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { DashboardService } from './dashboard.service';
import { LoginService } from './login.service';
import { AlertService } from './alert.service';
import { MomentjsService } from './momentjs.service';
import { LoadingService } from './loading.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CheckTokenService } from './check-token.service';
import { Location } from '@angular/common';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  urlPhoto: any = "";
  blockDate:any = [];

  /* History Router Navigate */
  public historyRouter: any = []
  public accesses_id: string = "0"
  constructor(
    /* Service basic */
    public router: Router,
    public loadingService: LoadingService,
    public momentJS: MomentjsService,
    public alertService: AlertService,
    public fb: FormBuilder,
    public storageService: StorageService,
    public toastService: ToastService,
    public activatedRoute: ActivatedRoute,
    public platform: Platform,
    public weatherAPIService: WeatherAPIService,
    public geolocation: GeolocationService,
    public checkTokenService: CheckTokenService,
    public alertController: AlertController,
    public location: Location,
    public utilitiesService: UtilitiesService,

    /* Service  API */
    public loginService: LoginService,
    public dashboardService: DashboardService,
    public porjectService: ProjectService,
    public activiesService: ActiviesService,
    public taksService: TaskService,
    public uploadArchivesService: UploadArchivesService,
    public galleryService: GalleryService,
    public userProjectService: UserProjectService,
    public anamoliesService: AnamoliesService,
    public equipamentService: EquipmentsService,
    public otherIncidentsService: OtherIncidentsService,
    public projectIncidentsService: ProjectIncidentsService,
    public implantationService: ImplantationService,
    public inspectionService: InspectionService,
    public finishService: FinishService,
    public closureService: ClosureService

  ) {



  }

  calculeProgress(value) {
    return Number(value) / 100
  }

  parseInt(value) {
    return parseInt(value)
  }

  getAccess() {
    var access = this.storageService.get("info")
    this.accesses_id = access.accesses_id
  }

  async openCapacitorSite(url) {
    url: String(url);
    await Browser.open({ url: url });
  };


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    public http: HttpClient
  ) { }

  login(data) {
    return this.http.post(`${environment.api}auth/login`, data)
    .toPromise()
    .then(data => data)
  }

  createAccount(data) {
    return this.http.post(`${environment.api}users/apple`, data)
      .toPromise()
      .then(data => data)
  }
  
  createAccountApple(data) {
    return this.http.post(`${environment.api}users/apple`, data)
      .toPromise()
      .then(data => data)
  }

  updateAccount(id, data) {
    return this.http.post("http://localhost:8100/login" + id + "", data)
      .toPromise()
      .then(data => data)
  }

  
  inactiveAccount(id, data) {
    return this.http.post("http://localhost:8100/login" + id + "", data)
      .toPromise()
      .then(data => data)
  }

  listAccess(){
    return this.http.get(`${environment.api}accesses/apple`)
    .toPromise()
    .then(data => data)
  }


  listAccessNormalize(){
    return this.http.get(`${environment.api}accesses`)
    .toPromise()
    .then(data => data)
  }


  createAccountNormalize(data,file) {
    var formData = new FormData;
    formData.append("file", file)
    formData.append("name", data.name)
    formData.append("number", data.number)
    formData.append("accesses_id", data.accesses_id)
    formData.append("phone", data.phone)
    formData.append("password", data.password)
    formData.append("email", data.email )
    formData.append("status", data.status)
    formData.append("email_verified_at", data.email_verified_at)
    formData.append("remember_token", data.remember_token)
    formData.append("is_subcontracted", data.is_subcontracted)
    formData.append("subcontrated_entity", data.subcontrated_entity)
    return this.http.post(`${environment.api}users`, formData)
      .toPromise()
      .then(data => data)
  }

  updateAccountNormalize(data,file) {
    var formData = new FormData;
    formData.append("file", file)
    formData.append("name", data.name)
    formData.append("number", data.number)
    formData.append("accesses_id", data.accesses_id)
    formData.append("phone", data.phone)
    formData.append("password", data.password)
    formData.append("email", data.email )
    formData.append("status", data.status)
    formData.append("email_verified_at", data.email_verified_at)
    formData.append("remember_token", data.remember_token)
    formData.append("is_subcontracted", data.is_subcontracted)
    formData.append("subcontrated_entity", data.subcontrated_entity)
    return this.http.post(`${environment.api}users/put/${data.id}`, formData)
      .toPromise()
      .then(data => data)
  }

  removerPermanent() {
    return this.http.post(`${environment.api}users/remove`, {})
    .toPromise()
    .then(data => data)
  }
}

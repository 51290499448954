import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  getUsers() {
    return this.http.get(`${environment.api}users`)
      .toPromise()
      .then(data => data)
  }

  getHistories() {
    return this.http.get(`${environment.api}histories`)
      .toPromise()
      .then(data => data)
  }

  getHistoriesFilter(id) {
    return this.http.get(`${environment.api}histories/${id}`)
      .toPromise()
      .then(data => data)
  }

}

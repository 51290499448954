import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ImplantationService {

  constructor(
    private http:HttpClient
  ) { }

  getImplantation(id) {
    return this.http.get(`${environment.api}implantation/get/${id}`)
      .toPromise()
      .then(data => data)
  }


  postImplantation(data) {
    return this.http.post(`${environment.api}implantation`,data)
      .toPromise()
      .then(data => data)
  }

  
  putImplantation(data) {
    return this.http.put(`${environment.api}implantation/${data.id}`,data)
      .toPromise()
      .then(data => data)
  }
}

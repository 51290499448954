

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OtherIncidentsService {

  constructor(
    private http:HttpClient
  ) { }

  getOtherIncidentes(id) {
    return this.http.get(`${environment.api}other-incidents/get/${id}`)
      .toPromise()
      .then(data => data)
  }


  postOtherIncidentes(data) {
    return this.http.post(`${environment.api}other-incidents`,data)
      .toPromise()
      .then(data => data)
  }

  
  putOtherIncidentes(data) {
    return this.http.put(`${environment.api}other-incidents/${data.id}`,data)
      .toPromise()
      .then(data => data)
  }

  deleteOtherIncidentes(id) {
    return this.http.delete(`${environment.api}other-incidents/${id}`)
      .toPromise()
      .then(data => data)
  }
}

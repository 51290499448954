import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './component/interceptor/interceptor.interceptor';



@NgModule({
  declarations:
    [
      AppComponent
    ],
  entryComponents:
    [],
  imports:
    [
      BrowserModule,
      IonicModule.forRoot(
        { mode: "ios" }
      ),
      AppRoutingModule,
      HttpClientModule,
      ReactiveFormsModule,
    ],
  providers:
    [{
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },],
  bootstrap:
    [
      AppComponent
    ],
})
export class AppModule { }

import { environment } from './../../environments/environment.prod';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private platform: Platform
  ) { }

  clear() {
    return localStorage.clear()
  }

  set(key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  remove(key) {
    return localStorage.removeItem(key);
  }

}

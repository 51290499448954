import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var authReq: HttpRequest<any> = req
    var AUTH_TOKEN: any = localStorage.getItem('hash');

    if (AUTH_TOKEN) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + (AUTH_TOKEN ? AUTH_TOKEN.replace(/"/g, '') : "") + '')
      });
    }
    /* NEXT */
    return next.handle(authReq);
  }
}

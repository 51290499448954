import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AnamoliesService {

  constructor(
    private http: HttpClient
  ) { }


  getWorkerIncidents(id) {
    return this.http.get(`${environment.api}workerincidents/${id}`)
      .toPromise()
      .then(data => data)
  }

  postWorkerIncidents(data) {
    return this.http.post(`${environment.api}workerincidents`, data)
      .toPromise()
      .then(data => data)
  }

  putWorkerIncidents(data) {
    return this.http.put(`${environment.api}workerincidents/${data.id}`, data)
      .toPromise()
      .then(data => data)
  }

  getProjectIncidents(id) {
    return this.http.get(`${environment.api}project-incidents/${id}`)
      .toPromise()
      .then(data => data)
  }

  deleteProjectIncidents(id) {
    return this.http.delete(`${environment.api}workerincidents/${id}`)
      .toPromise()
      .then(data => data)
  }

}

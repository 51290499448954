import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {


  constructor(
    private http: HttpClient
  ) { }

  getInspection(id, date) {
    return this.http.post(`${environment.api}inspections/get/${id}`, date)
      .toPromise()
      .then(data => data)
  }


  postInspection(data) {
    return this.http.post(`${environment.api}inspections`, data)
      .toPromise()
      .then(data => data)
  }


  putInspection(data) {
    return this.http.put(`${environment.api}inspections/${data.id}`, data)
      .toPromise()
      .then(data => data)
  }
}
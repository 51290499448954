import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CheckTokenService {

  constructor(
    private storageService: StorageService
  ) { }

  tokenCheck() {
    var token = this.storageService.get('hash')
    /* checkTokenisValid */
    const date = this.checkExpirationTokenPayment(token)
    if (date != null) {
      if (date.valueOf() > new Date().valueOf()) {
        return true
      } else {
   return false
      }
    } else {
      return false
    }
  }

  checkExpirationTokenPayment(token) {
    if (token) {
      /* existe token */
      const decodeToken: any = jwt_decode(token)
      if (decodeToken.exp === undefined) {
        return null
      }
      const date = new Date(0);
      date.setUTCMilliseconds(decodeToken.exp * 1000);
      return date
    } else {
      return null
    }
  }
}

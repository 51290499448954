import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class EquipmentsService {

  constructor(
    public http: HttpClient
  ) { }

  postEquipments(data, file) {
    var formData = new FormData;
    formData.append("file", file ? file : undefined)
    formData.append("name", data.name)
    formData.append("number", data.number)
    formData.append("description", data.description)
    formData.append("serial_number", data.serial_number)
    formData.append("maintenance_date", data.maintenance_date)
    formData.append("status", data.status)
    formData.append("location", data.location)
    return this.http.post(`${environment.api}equipments`, formData)
      .toPromise()
      .then(data => data)
  }

  updateEquipments(data, file) {
    var formData = new FormData;
    formData.append("file", file)
    formData.append("name", data.name)
    formData.append("project_id", data.project_id)
    formData.append("description", data.description)
    formData.append("unit", data.unit)
    formData.append("quantity", data.quantity)
    formData.append("status", data.status)
    formData.append("order", data.order)
    return this.http.post(`${environment.api}equipments/${data.id}`, formData)
      .toPromise()
      .then(data => data)
  }


  getEquipentsMovements(id) {
    return this.http.get(`${environment.api}equipments-moviments/get/${id}`)
      .toPromise()
      .then(data => data)
  }

  postMovements(data) {
    return this.http.post(`${environment.api}equipments-moviments`, data)
      .toPromise()
      .then(data => data)
  }

  deleteMovements(id) {
    return this.http.delete(`${environment.api}equipments-moviments/${id}`)
      .toPromise()
      .then(data => data)
  }

  putMovements(data) {
    return this.http.put(`${environment.api}equipments-moviments/${data.id}`, data)
      .toPromise()
      .then(data => data)
  }
}

export const environment = {
  production: true,


  /* Api Testing */
/*     api: "http://127.0.0.1:8000/api/", */
    /* Api Tabela raiz */
  api: "https://web.tabelaraiz.pt/apitr/backofficeTR/public/api/",
  /* Api Prod */
/*   api: "  https://observei.com.br/apitr/backofficeTR/public/api/", */
/*   urlPhoto: "http://127.0.0.1:8000/", // LOCAL
  urlPhoto: "https://observei.com.br/apitr/backofficeTR/public/", // OBS */
  urlPhoto: "https://web.tabelaraiz.pt/apitr/backofficeTR/public/", // TR
  debug: false,
};

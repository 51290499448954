import { GuardGuard } from './component/guard/guard.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'activities',
    pathMatch: 'full'
  },
  {
    path: 'sign',
    loadChildren: () => import('./page/sign/sign.module').then( m => m.SignPageModule)
  },
  {
    path: 'sign/:home/:id',
    loadChildren: () => import('./page/sign/sign.module').then( m => m.SignPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'create',
    loadChildren: () => import('./page/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'activities',
    loadChildren: () => import('./page/activities/activities.module').then( m => m.ActivitiesPageModule),
    canActivate: [GuardGuard]
    
  },
  {
    path: 'project',
    loadChildren: () => import('./page/project/project.module').then( m => m.ProjectPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'construction',
    loadChildren: () => import('./page/construction/construction.module').then( m => m.ConstructionPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'equipment',
    loadChildren: () => import('./page/equipment/equipment.module').then( m => m.EquipmentPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'identification',
    loadChildren: () => import('./page/identification/identification.module').then( m => m.IdentificationPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'oversight',
    loadChildren: () => import('./page/oversight/oversight.module').then( m => m.OversightPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'checkday',
    loadChildren: () => import('./page/checkday/checkday.module').then( m => m.CheckdayPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'list',
    loadChildren: () => import('./page/list/list.module').then( m => m.ListPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'structure',
    loadChildren: () => import('./page/structure/structure.module').then( m => m.StructurePageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'addup',
    loadChildren: () => import('./page/addup/addup.module').then( m => m.AddupPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'detail',
    loadChildren: () => import('./page/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'inspection',
    loadChildren: () => import('./page/inspection/inspection.module').then( m => m.InspectionPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'assignment',
    loadChildren: () => import('./page/assignment/assignment.module').then( m => m.AssignmentPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'task',
    loadChildren: () => import('./page/task/task.module').then( m => m.TaskPageModule),
    canActivate: [GuardGuard]
  },

  {
    path: 'worker',
    loadChildren: () => import('./page/worker/worker.module').then( m => m.WorkerPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'stakeholders',
    loadChildren: () => import('./page/stakeholders/stakeholders.module').then( m => m.StakeholdersPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'description',
    loadChildren: () => import('./page/description/description.module').then( m => m.DescriptionPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'anomalies',
    loadChildren: () => import('./page/anomalies/anomalies.module').then( m => m.AnomaliesPageModule),
    canActivate: [GuardGuard]
  },

  {
    path: 'repair',
    loadChildren: () => import('./page/repair/repair.module').then( m => m.RepairPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'warning',
    loadChildren: () => import('./page/warning/warning.module').then( m => m.WarningPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'save',
    loadChildren: () => import('./page/save/save.module').then( m => m.SavePageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'designation',
    loadChildren: () => import('./page/designation/designation.module').then( m => m.DesignationPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'occurrences',
    loadChildren: () => import('./page/occurrences/occurrences.module').then( m => m.OccurrencesPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'implantation',
    loadChildren: () => import('./page/implantation/implantation.module').then( m => m.ImplantationPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'gallery',
    loadChildren: () => import('./page/gallery/gallery.module').then( m => m.GalleryPageModule),
    canActivate: [GuardGuard]
  },
  
  {
    path: 'equipamen-movements',
    loadChildren: () => import('./page/equipamen-movements/equipamen-movements.module').then( m => m.EquipamenMovementsPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'user-incidents',
    loadChildren: () => import('./page/user-incidents/user-incidents.module').then( m => m.UserIncidentsPageModule),    
    canActivate: [GuardGuard]
  },
  {
    path: 'person-incidents',
    loadChildren: () => import('./page/person-incidents/person-incidents.module').then( m => m.PersonIncidentsPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'upload',
    loadChildren: () => import('./page/upload/upload.module').then( m => m.UploadPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'listinspection',
    loadChildren: () => import('./page/listinspection/listinspection.module').then( m => m.ListinspectionPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'set-inspection',
    loadChildren: () => import('./page/set-inspection/set-inspection.module').then( m => m.SetInspectionPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'closure-anamolies',
    loadChildren: () => import('./page/closure-anamolies/closure-anamolies.module').then( m => m.ClosureAnamoliesPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'closure-list-finish',
    loadChildren: () => import('./page/closure-list-finish/closure-list-finish.module').then( m => m.ClosureListFinishPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'closure-add',
    loadChildren: () => import('./page/closure-add/closure-add.module').then( m => m.ClosureAddPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'closure-anomalies-add',
    loadChildren: () => import('./page/closure-anomalies-add/closure-anomalies-add.module').then( m => m.ClosureAnomaliesAddPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'list-user-login',
    loadChildren: () => import('./page/list-user-login/list-user-login.module').then( m => m.ListUserLoginPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'associate-work',
    loadChildren: () => import('./page/associate-work/associate-work.module').then( m => m.AssociateWorkPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./page/history/history.module').then(m => m.HistoryPageModule),
    canActivate: [GuardGuard]
  },
  {
    path: 'history-project',
    loadChildren: () => import('./page/history-project/history-project.module').then(m => m.HistoryProjectPageModule),
    canActivate: [GuardGuard]
  },


 





 
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    public http: HttpClient
  ) { }

  getProject() {
    return this.http.get(`${environment.api}projects`)
      .toPromise()
      .then(data => data)
  }

  postProject(data, file) {
    var formData = new FormData;
    formData.append("file", file)
    formData.append("name", data.name)
    formData.append("number", data.number)
    formData.append("client_name", data.client_name)
    formData.append("address", data.address)
    formData.append("zipcode", data.zipcode)
    formData.append("country", data.country)
    formData.append("latitude", data.latitude)
    formData.append("longitude", data.longitude)
    formData.append("status", data.status)
    formData.append("start_date", data.start_date)
    formData.append("end_date", data.end_date)
    formData.append("estimated_date", data.estimated_date)
    formData.append("value", data.value)
    return this.http.post(`${environment.api}projects`, formData)
      .toPromise()
      .then(data => data)
  }


  putProject(data, file) {
    var formData = new FormData;
    formData.append("file", file)
    formData.append("name", data.name)
    formData.append("number", data.number)
    formData.append("client_name", data.client_name)
    formData.append("address", data.address)
    formData.append("zipcode", data.zipcode)
    formData.append("country", data.country)
    formData.append("latitude", data.latitude)
    formData.append("longitude", data.longitude)
    formData.append("status", data.status)
    formData.append("start_date", data.start_date)
    formData.append("end_date", data.end_date)
    formData.append("estimated_date", data.estimated_date)
    formData.append("value", data.value)
    return this.http.post(`${environment.api}projects/put/${data.id}`, formData)
      .toPromise()
      .then(data => data)
  }

  getProjectUser(id) {
    return this.http.get(`${environment.api}projects/user/${id}`)
      .toPromise()
      .then(data => data)
  }


 deleteProjectUser(id) {
    return this.http.delete(`${environment.api}projects/${id}`)
      .toPromise()
      .then(data => data)
  }

  getProjectUserEdit(type, data?) {
    return this.http.put(`${environment.api}projects/user/${type}/${data.id_manager}`, data ?? {})
      .toPromise()
      .then(data => data)
  }
}
